import styled, { css } from 'styled-components'

import { FireImage } from '@nx/fire/image'
import { Body, Heading, Label } from '@nx/fire/typography'

interface Compact {
  $isAlwaysCompact: boolean
}

const gridTemplates = {
  small: `"B B A A A"
          "C C C C C"`,
  smallNoImage: `"A A A A A"
                 "C C C C C"`,
  large: `"A A A A A"
          "B B B B B"
          "C C C C C"`,
}

export const StyledCard = styled.article<
  { $alignment: 'start' | 'auto' | 'baseline' } & Compact
>`
  box-shadow: ${({ theme }) => theme.shadows.extraSmall};
  background: ${({ theme }) => theme.colours.primary.white};
  text-align: left;
  min-width: 265px;
  width: 100%;
  padding: 20px 15px;
  border-radius: 3px;

  ${({ theme, $alignment }) => css`
    ${theme.media.up.lg} {
      align-self: ${$alignment};
    }
  `}

  ${({ $isAlwaysCompact, theme }) =>
    !$isAlwaysCompact &&
    css`
      ${theme.media.up.lg} {
        min-width: 240px;
      }
    `}
`

export const EventContentContainer = styled.div<
  Compact & { $showImage: boolean }
>`
  display: grid;
  grid-gap: 10px;
  height: 100%;
  grid-template-columns: repeat(5, 1fr);
  grid-template-areas: ${gridTemplates.small};

  ${({ $showImage }) =>
    !$showImage &&
    css`
      grid-gap: 0;
      grid-template-areas: ${gridTemplates.smallNoImage};
    `}

  ${({ $isAlwaysCompact, theme }) =>
    !$isAlwaysCompact &&
    css`
      ${theme.media.up.lg} {
        grid-template-areas: ${gridTemplates.large};
        grid-template-rows: max-content auto max-content;
      }
    `}
`

export const EventContent = styled.div`
  grid-area: A;
`

export const EventType = styled(Body)`
  font-weight: lighter;
  text-transform: uppercase;
  margin-bottom: 1rem;
`

export const EventTitle = styled(Heading)`
  margin-bottom: 10px;
  display: block;
`

const maxLinesStyles = (maxLines: number) => css`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: ${maxLines};
  line-clamp: ${maxLines};
  -webkit-box-orient: vertical;
`

export const EventDescription = styled(Body)<{ $maxLines?: number }>`
  ${({ $maxLines }) =>
    $maxLines &&
    $maxLines > 0 &&
    css`
      ${maxLinesStyles($maxLines)}
      & > p {
        ${maxLinesStyles($maxLines)}
      }

      & > * {
        :not(:first-child) {
          display: none;
        }
      }
    `}
`

export const EventImage = styled.div<Compact>`
  grid-area: B;

  ${({ $isAlwaysCompact, theme }) =>
    !$isAlwaysCompact &&
    css`
      ${theme.media.up.xl} {
        align-self: end;
      }
    `}
`

export const EventCTAContainer = styled.div`
  grid-area: C;
  align-self: end;
`

export const StyledEventDetails = styled(Body)<{ $uppercase?: boolean }>`
  ${({ theme }) => theme.fontStyles.sourceSans.bold}
  ${({ $uppercase }) =>
    $uppercase &&
    css`
      text-transform: uppercase;
    `}
`

const imageContainerStyle = ($hasImage: boolean, colour: string) => css`
  background-color: ${!$hasImage ? colour : null};
  padding: ${!$hasImage ? '17px 0' : null};
`

export const StyledImageContainer = styled.div<
  { $hasImage: boolean } & Compact
>`
  display: flex;

  ${({ theme: { colours, media }, $hasImage, $isAlwaysCompact }) => css`
    ${$isAlwaysCompact
      ? imageContainerStyle($hasImage, colours.grey['30'])
      : css`
          ${media.down.lg} {
            ${imageContainerStyle($hasImage, colours.grey['30'])}
          }
        `}
  `}
`

export const StyledFireImage = styled(FireImage)`
  border-radius: 4px;
  object-fit: contain;
  width: 100% !important;
  max-height: 330px !important;
  position: relative !important;
  height: unset !important;
`

export const StyledStatusLabel = styled(Label)<{ $lightweight: boolean }>`
  margin-bottom: 10px;

  ${({ $lightweight, theme }) =>
    $lightweight &&
    css`
      font-weight: normal;
      color: ${theme.colours.grey['60']};
    `}
`

export const StyledEventCTALink = styled(Label)<{ $lightweight: boolean }>`
  color: ${({ theme }) => theme.colours.primary.primary};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;

  ${({ $lightweight, theme }) =>
    $lightweight &&
    css`
      color: ${theme.colours.primary.black};
    `}
  svg {
    width: 18px;
    height: 18px;
  }
`

export const StyledEventCTAIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

import { AlgoliaAuction } from '@nx/algolia'
import { AuctionBiddingEngine } from '@nx/global-types'
import { TypesenseAuction } from '@nx/typesense'
import { logger } from '@nx/utils/logger'

import { populateUrl } from '@web/helpers/utils/urlBuilders/populateUrl'

import { AuctionGridItem } from '../../../components/common/AuctionGrid'

export const mapAlgoliaAuctionToGridItem = (
  auction: AlgoliaAuction | TypesenseAuction
): AuctionGridItem | null => {
  try {
    if (
      typeof auction?.dates?.start?.timestamp !== 'number' ||
      typeof auction?.dates?.end?.timestamp !== 'number' ||
      typeof auction?.dates?.start?.timezone?.iana !== 'string' ||
      typeof auction?.dates?.start?.timezone?.abbreviation !== 'string' ||
      typeof auction?.lots?.total !== 'number'
    ) {
      throw new Error()
    }

    return {
      id: auction.id,
      name: auction.auctionTitle,
      type: auction.auctionType,
      status: auction.auctionStatus,
      biddingStatus: auction.biddingStatus,
      localTimezone: auction.dates.start.timezone.iana,
      timezoneFormat: auction.dates.start.timezone.abbreviation,
      startDate: auction.dates.start.timestamp * 1000,
      endDate: auction.dates.end.timestamp * 1000,
      location:
        auction.biddingEngine === AuctionBiddingEngine.BRK ||
        auction.biddingEngine === AuctionBiddingEngine.BUK
          ? auction.country?.name || ''
          : auction.venue?.name || '',
      biddingEngine: auction.biddingEngine,
      numLots: auction.lots.total,
      imageUrl: auction.image
        ? populateUrl(auction.image.url, auction.image.URLParams)
        : '',
      monthAndYear: auction.monthAndYear,
      consignmentEndDate: auction.dates.consignment?.timestamp
        ? auction.dates.consignment?.timestamp * 1000
        : null,
      brand: auction.brand,
      slug: auction.slug ?? '',
    }
  } catch {
    logger.error(auction, `Error mapping Algolia auction to grid item`)
    return null
  }
}

import { addDays, isBefore } from 'date-fns'
import { toZonedTime } from 'date-fns-tz'

import {
  AuctionProgressStatus,
  AuctionType,
  EAuctionType,
} from '@nx/global-types'

import { formatDateRange } from '../formatDateRange'

export const auctionDate = (
  localTimezone: string,
  timezoneFormat: string,
  startDate: string | number,
  endDate: string | number,
  auctionType: EAuctionType,
  status: AuctionProgressStatus
) => {
  const nowDate = toZonedTime(new Date(), localTimezone)
  const localisedStartDate = toZonedTime(startDate, localTimezone)
  const localisedEndDate = toZonedTime(endDate, localTimezone)

  // Auctions typically happen within 60 days of the current date and always within 180 days, so we should only
  // display it on historic/ended auctions or if it's in the next calendar year.
  const shouldDisplayYear =
    status === AuctionProgressStatus.ended ||
    localisedEndDate.getFullYear() !== nowDate.getFullYear()

  // Should only display time if auction is not in progress, it is live, and is happening within the next 24h.
  const shouldDisplayTime =
    auctionType === AuctionType.live &&
    [
      AuctionProgressStatus.consignment,
      AuctionProgressStatus.previewReady,
      AuctionProgressStatus.comingSoon,
      AuctionProgressStatus.ready,
    ].includes(status) &&
    isBefore(localisedStartDate, addDays(nowDate, 1))

  return formatDateRange({
    startDate,
    endDate,
    timezone: localTimezone,
    timezoneAbbreviation: timezoneFormat,
    options: {
      hideYear: !shouldDisplayYear,
      hideTime: !shouldDisplayTime,
    },
  })
}

import { HTMLAttributes, ReactNode, forwardRef } from 'react'

import { ArrowRightIcon } from '@nx/fire/assets'
import { FireLink } from '@nx/fire/link'

import {
  EventCTAContainer,
  EventContent,
  EventContentContainer,
  EventDescription,
  EventImage,
  EventTitle,
  EventType,
  StyledCard,
  StyledEventCTALink,
  StyledEventDetails,
  StyledFireImage,
  StyledImageContainer,
  StyledStatusLabel,
} from './fire-event-card.styles'

export const FireEventCard = forwardRef<HTMLDivElement, FireEventCardProps>(
  (
    {
      accentDetails,
      ctaLink,
      ctaText,
      date,
      description,
      descriptionLines,
      eventType,
      imageUrl,
      isAlwaysCompact = false,
      isLightCta,
      isLightStatus,
      location,
      showArrowIcon,
      showImage,
      statusLabel,
      title,
      selfAlignment = 'start',
      ...props
    },
    ref
  ) => {
    return (
      <StyledCard
        $alignment={selfAlignment}
        $isAlwaysCompact={isAlwaysCompact}
        ref={ref}
        {...props}
      >
        <EventContentContainer
          $isAlwaysCompact={isAlwaysCompact}
          $showImage={showImage}
        >
          <EventContent>
            {eventType && <EventType $level="S">{eventType}</EventType>}
            <FireLink href={ctaLink}>
              <EventTitle as="span" $level={6}>
                {title}
              </EventTitle>
            </FireLink>
            {description && (
              <EventDescription
                as="div"
                $level="subtitle"
                $maxLines={descriptionLines}
              >
                {description}
              </EventDescription>
            )}
            <StyledEventDetails $level="S" $uppercase={accentDetails}>
              {[date, location].filter(Boolean).join(' | ')}
            </StyledEventDetails>
          </EventContent>
          {showImage && (
            <EventImage $isAlwaysCompact={isAlwaysCompact}>
              <FireLink href={ctaLink}>
                <StyledImageContainer
                  $isAlwaysCompact={isAlwaysCompact}
                  $hasImage={Boolean(imageUrl)}
                >
                  <StyledFireImage
                    src={imageUrl ? `${imageUrl}&width=320` : ''}
                    width="100"
                    height="0"
                    alt="Auction cover image"
                  />
                </StyledImageContainer>
              </FireLink>
            </EventImage>
          )}
          <EventCTAContainer>
            {Boolean(statusLabel) && (
              <StyledStatusLabel
                $level="S"
                $lightweight={Boolean(isLightStatus)}
              >
                {statusLabel}
              </StyledStatusLabel>
            )}
            <FireLink href={ctaLink}>
              <StyledEventCTALink
                as="span"
                $lightweight={isLightCta}
                $level={isLightCta ? 'S' : 'M'}
              >
                <span>{ctaText}</span>
                {showArrowIcon && <ArrowRightIcon />}
              </StyledEventCTALink>
            </FireLink>
          </EventCTAContainer>
        </EventContentContainer>
      </StyledCard>
    )
  }
)

export interface FireEventCardProps
  extends Omit<HTMLAttributes<HTMLDivElement>, 'title'> {
  accentDetails?: boolean
  ctaLink: string
  ctaText: ReactNode
  date: string
  description?: ReactNode
  descriptionLines?: number
  eventType?: string
  imageUrl?: string
  isAlwaysCompact?: boolean
  isLightCta: boolean
  isLightStatus?: boolean
  location: string
  showArrowIcon?: boolean
  showImage: boolean
  statusLabel?: ReactNode
  title: ReactNode
  selfAlignment?: 'start' | 'auto'
}

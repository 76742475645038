import styled, { css } from 'styled-components'
import { AddToCalendarIcon } from '@nx/fire/assets'

export const StyledGrid = styled.div`
  margin-top: 25px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1em;

  ${({ theme }) => css`
    ${theme.media.up.xl} {
      margin-top: 37px;
      grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
      gap: 3em 2.5em;
    }
  `}
`

export const MonthAndYearSeparatorContainer = styled.div<{
  $hideBackToTop: boolean
}>`
  grid-column: 1/-1;

  > div:first-child {
    margin-top: 15px;
    margin-bottom: 0;
  }

  ${({ theme }) => css`
    ${theme.media.up.xl} {
      margin-bottom: -15px;
    }
  `}

  ${({ $hideBackToTop }) =>
    $hideBackToTop &&
    css`
      a {
        display: none;
      }
    `}
`

export const MonthAndYearContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

export const StyledAddToCalendarIcon = styled(AddToCalendarIcon)`
  max-width: 30px;
`
